document.addEventListener("DOMContentLoaded", function () {
  const header = document.querySelector(".site-header");

  window.addEventListener("scroll", () => {
    if (window.scrollY >= 50) {
      header.classList.add("scrolled");
    } else {
      if (header.classList.contains("scrolled")) {
        header.classList.remove("scrolled");
      }
    }
  });
});

